<template>
  <div class="absolute bottom-0">
    <div v-if="showInstallPWA" class="pwa-install-popup">
      <p class="leading-loose">
        To install the app, tap
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
        >
          <path
            d="M240-40q-33 0-56.5-23.5T160-120v-440q0-33 23.5-56.5T240-640h120v80H240v440h480v-440H600v-80h120q33 0 56.5 23.5T800-560v440q0 33-23.5 56.5T720-40H240Zm200-280v-447l-64 64-56-57 160-160 160 160-56 57-64-64v447h-80Z"
          /></svg
        ><br />
        and then <strong>Add to Home Screen</strong>
      </p>
      <button
        @click="closePopup"
        class="top-0 right-0 mt-2 mr-2 px-1 py-1 absolute bg-gray-200 rounded-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
        >
          <path
            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupPWAInstall",
  data() {
    return {
      showInstallPWA: false,
    };
  },
  mounted() {
    this.checkPWAInstallStatus();
  },
  methods: {
    checkPWAInstallStatus() {
      // Detect iOS devices
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      // Check if running in standalone mode
      const isInStandaloneMode =
        "standalone" in window.navigator && window.navigator.standalone;
      // Show install popup if on iOS and not in standalone mode
      if (isIOS && !isInStandaloneMode) {
        this.showInstallPWA = true;
      }
    },
    closePopup() {
      this.showInstallPWA = false;
    },
  },
};
</script>

<style>
.pwa-install-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}
</style>
