import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat/app";
import SignInView from "@views/SignInView.vue";
import WindowView from "@views/WindowView.vue";
import HomeView from "@views/HomeView.vue";
import LandingPage from "@views/LandingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: LandingPage,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignInView,
    meta: {
      loggedOut: true,
    },
  },
  {
    path: "/invitation",
    name: "invitation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "information" */ "@views/InvitationView.vue"),
    meta: {
      loggedOut: true,
    },
  },
  {
    path: "/signup",
    name: "signup",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "information" */ "@views/SignUpView.vue"),
    meta: {
      loggedOut: true,
    },
  },
  {
    path: "/signup/:invitationCode",
    name: "signupinvitation",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "@views/SignUpView.vue"),
    meta: {
      loggedOut: true,
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@views/ForgotPassword.vue"),
    meta: {
      loggedOut: true,
    },
  },
  {
    path: "/area",
    name: "area",
    children: [
      {
        path: "home",
        name: "home",
        component: HomeView,
      },
      {
        path: "favourites",
        name: "favourites",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/FavouriteView.vue"),
      },
      {
        path: "add",
        name: "add",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/SearchView.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/SearchUserView.vue"),
      },
      {
        path: "mybooks",
        name: "mybooks",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/ListView.vue"),
      },
      {
        path: "mybooks/:slug",
        name: "mybookslist",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/ListView.vue"),
      },
      {
        path: "book/:slug",
        name: "bookdetails",
        component: () =>
          import(/* webpackChunkName: "core" */ "@views/BookView.vue"),
      },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "menu" */ "@views/ProfileView.vue"),
      },
      {
        path: "profile/:username",
        name: "publicprofile",
        component: () =>
          import(/* webpackChunkName: "menu" */ "@views/PublicProfileView.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () =>
          import(/* webpackChunkName: "menu" */ "@views/SettingsView.vue"),
      },
    ],
    redirect: { name: "home" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: WindowView,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/404",
    name: "Error",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "error" */ "@views/ErrorPage.vue"),
  },
  {
    path: "*",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "error" */ "@views/ErrorPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        next();
      } else {
        // alert("You must be logged in to see this page");
        next({
          path: "/",
        });
      }
    });
  } else if (to.matched.some((record) => record.meta.loggedOut)) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // alert("You are already logged in");
        next({
          path: "/area/home",
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
