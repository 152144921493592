<template>
  <div class="flex flex-col p-10 max-w-6xl mx-auto">
    <router-link
      to="/area/mybooks/read"
      class="bg-white shadow-md rounded-3xl p-4 md:h-56 hover:scale-105 transition hover:cursor-pointer"
    >
      <div class="flex-none md:flex">
        <div
          class="h-full w-full md:h-52 md:w-52 md:mr-4 md:ml-2 md:block hidden"
        >
          <img
            src="@assets/img/Challenge.png"
            alt="Challenge Image"
            class="w-full object-scale-down md:object-cover md:h-52 rounded-2xl max-h-72"
          />
        </div>
        <div
          class="w-full mx-auto px-3 flex flex-col justify-between mb-4 py-2"
        >
          <div class="flex flex-row justify-between">
            <div class="flex flex-col mr-4 w-full">
              <div class="flex flex-col md:flex-row justify-between">
                <div class="hidden md:block text-blue-500 font-medium">
                  Challenges
                </div>
                <div
                  v-if="bookRead >= milestone"
                  class="mx-auto md:mr-0 mb-4 md:mb-0 flex-row font-bold text-right text-blue-500 bg-blue-100 rounded-full px-2 py-1 md:flex hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    /></svg
                  >Challenge Completed!
                </div>
              </div>
              <div class="flex flex-row mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="flex-auto h-8 w-8 mr-4 md:hidden shrink-0"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#F19E39"
                >
                  <path
                    d="M280-120v-80h160v-124q-49-11-87.5-41.5T296-442q-75-9-125.5-65.5T120-640v-40q0-33 23.5-56.5T200-760h80v-80h400v80h80q33 0 56.5 23.5T840-680v40q0 76-50.5 132.5T664-442q-18 46-56.5 76.5T520-324v124h160v80H280Zm0-408v-152h-80v40q0 38 22 68.5t58 43.5Zm200 128q50 0 85-35t35-85v-240H360v240q0 50 35 85t85 35Zm200-128q36-13 58-43.5t22-68.5v-40h-80v152Zm-200-52Z"
                  />
                </svg>
                <h2 class="flex-auto text-lg md:text-xl font-bold">
                  {{ year }} Reading Challenge is here!
                </h2>
              </div>
            </div>
          </div>

          <div>
            <div
              v-if="loading"
              class="my-4 pulse bg-gray-200 md:w-48 h-4 rounded-full"
            ></div>
            <div v-else class="flex flex-row py-4 text-sm text-gray-500">
              <div class="flex-1 inline-flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                  />
                </svg>

                <p class="ml-2 text-xs md:text-base">
                  You currently have read
                  <span class="font-bold text-lg">{{ bookRead }}</span> out of
                  <span class="font-bold text-lg">{{ milestone }}</span> books!
                </p>
              </div>
              <div
                :class="bookRead ? '' : 'invisible'"
                class="flex flex-row items-center mx-2 text-gray-600 text-right shrink-0 text-2xl font-bold"
              >
                <div>{{ Math.round((bookRead / milestone) * 100) }} %</div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <div class="flex space-x-3 text-sm font-medium mb-4">
              <div
                class="flex justify-between flex-row items-center shadow w-full bg-gray-200 my-auto rounded-full text-xs"
              >
                <div
                  class="rounded-full text-xs leading-none min-w-[15px] text-white"
                  :style="
                    'width:' + (bookRead / milestone) * 100 + '%; height: 15px'
                  "
                  :class="loading ? 'bg-gray-200' : 'bg-blue-500'"
                ></div>
              </div>
            </div>
            <div
              class="flex flex-row-reverse items-center text-blue-500 font-semibold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
              Go to your Library
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import read from "@services/read.js";
import moment from "moment";

export default {
  name: "BookChallenge",
  data() {
    return {
      bookRead: null,
      loading: true,
      milestone: 20,
      year: moment().year(),
    };
  },
  created() {
    this.getReads();
  },
  methods: {
    async getReads() {
      let { data } = await read.getReads();
      this.bookRead = data.reduce(
        (a, { updated_at }) =>
          moment(moment(updated_at), "MM/DD/YYYY").format("YYYY") ==
          moment().year()
            ? a + 1
            : a,
        0
      );
      this.loading = false;
    },
  },
};
</script>
