<template>
  <div class="z-50 sticky top-0">
    <div class="dark:bg-gray-800 shadow-md">
      <nav class="opacity-95 bg-slate-50">
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div class="relative flex items-center justify-between h-16">
            <div
              class="absolute inset-y-0 left-0 flex items-center sm:hidden pl-2"
              :class="{ 'pb-2': !seenMobile }"
            >
              <!-- Notifications -->
              <div class="group">
                <span
                  v-if="!seenMobile"
                  class="flex relative h-3 w-3 top-[14px] -right-[19px]"
                >
                  <span
                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
                  ></span>
                  <span
                    class="relative inline-flex rounded-full h-3 w-3 bg-sky-500 text-[8px] text-white text-center"
                  >
                    <span class="ml-1">{{ username ? "1" : "2" }}</span></span
                  >
                </span>
                <button
                  type="button"
                  ref="notificationMobile"
                  @click="notificationToggleMobile"
                  @focusin="isNotificationFocusedMobile = true"
                  @focusout="isNotificationFocusedMobile = false"
                  :class="seen ? 'mt-[6px]' : 'mb-2'"
                  class="dark:bg-gray-800 p-1 rounded-full text-gray-400 dark:hover:text-white hover:text-gray-600"
                >
                  <span class="sr-only">View notifications</span>
                  <!-- Heroicon name: outline/bell -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>

                <div
                  class="dropdown-menu hidden absolute left-0 mt-2 border-2 bg-white rounded-md shadow-lg overflow-hidden z-20"
                  style="width: 20rem"
                >
                  <!-- <div class="py-2 border-b-2 border-gray-400">
                  <p class="text-gray-600 text-center mx-2 mr-2 font-bold">
                    Notifications
                  </p>
                </div> -->
                  <div v-if="!username" class="py-2 border-b">
                    <router-link
                      to="/area/settings"
                      class="flex flex-col px-4 py-3 hover:bg-gray-100 -mx-2 mv"
                    >
                      <div class="flex items-center">
                        <div class="w-[75px]">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            class="w-6 mx-auto"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                d="M394.4,942.1l47-160.1c4.9-16.9,14.6-32.2,28.2-44.3l120.5-119.4c-9.6-3.6-19.2-7-28.8-10.1c-6-2-44.2-19.2-20.3-91.6h-0.4c62.1-63.9,109.6-166.9,109.6-268.2c0-155.8-103.7-237.5-224.1-237.5c-120.5,0-223.5,81.7-223.5,237.5c0,101.7,47.2,205.1,109.7,268.9c24.3,63.9-19.2,87.6-28.3,90.9C158,653.8,10,737,10,819c0,22.2,0,8.7,0,30.7c0,105.4,192.6,134,382.8,136.9C390.1,972.1,390.1,956.9,394.4,942.1z"
                              />
                              <path
                                d="M831.6,657.3l-0.2,0.2l-36.8-36.9l0.2-0.2l-49.3-49.3L521.2,793.5c-3.1,2.5-5.7,5.6-6.9,9.7l-47,160.3c-2.1,7-0.1,14.6,5,19.7c3.8,3.8,8.9,5.9,14.1,5.9c1.9,0,3.7-0.3,5.6-0.8l160.4-47.1c3.8-1.1,6.8-3.5,9.1-6.3L886.3,712L831.6,657.3z M612.6,842.1l2.9,51.6l13,12.9l-86.2,25.3l-18.8-18.8l25.3-86.2l10.8,10.8L612.6,842.1z"
                              />
                              <path
                                d="M765.8,548.8l140.8,140.8l32.2-30.2L798.1,518.6L765.8,548.8z"
                              />
                              <path
                                d="M976,546.8l-67.5-67.5c-18.6-18.6-48.6-18.8-67.1-0.4l-20.7,20.9l135,135l20.7-21C994.7,595.5,994.6,565.4,976,546.8z"
                              />
                            </g>
                          </svg>
                        </div>
                        <p class="text-gray-600 text-sm mx-2 mr-2">
                          Update your
                          <span class="font-bold" href="#">Username!</span>
                        </p>
                      </div>

                      <a
                        class="text-blue-500 hover:text-blue-700 text-xs font-bold py-1 px-2 rounded-full mx-auto"
                      >
                        Go to settings
                      </a>
                    </router-link>
                  </div>
                  <div class="py-2">
                    <a
                      href="#"
                      class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
                    >
                      <span class="mx-2">👋</span>
                      <p class="text-gray-600 text-sm mx-2 mr-2">
                        Welcome to
                        <span class="font-bold" href="#">Keen Readers!</span>
                      </p>
                    </a>
                  </div>
                  <!-- <a
                  href="#"
                  class="block bg-gray-800 text-white text-center font-bold py-2"
                  >See all notifications</a
                > -->
                </div>
              </div>
            </div>
            <div
              class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
            >
              <router-link class="flex-shrink-0 flex items-center" to="/area">
                <Logo
                  class="block lg:hidden h-8 w-auto cursor-pointer"
                  alt="Logo Keen Readers"
                />
                <Logo
                  class="hidden lg:block h-8 w-auto cursor-pointer"
                  alt="Logo Keen Readers"
                />
              </router-link>
              <div class="flex-1 space-x-4 justify-center hidden sm:flex">
                <!-- Current: "dark:bg-gray-900 text-white", Default: "text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white hover:text-gray-600" -->
                <IconButton
                  v-for="item in this.navigation"
                  :key="item.name"
                  :item="item"
                ></IconButton>
              </div>
            </div>
            <div
              class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
            >
              <!-- Notifications -->
              <div class="group hidden sm:block">
                <span
                  v-if="!seen"
                  class="flex relative h-3 w-3 top-[14px] -right-[19px]"
                >
                  <span
                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
                  ></span>
                  <span
                    class="relative inline-flex rounded-full h-3 w-3 bg-sky-500 text-[8px] text-white text-center"
                  >
                    <span class="ml-1">{{ username ? "1" : "2" }}</span></span
                  >
                </span>
                <button
                  type="button"
                  ref="notification"
                  @click="notificationToggle"
                  @focusin="isNotificationFocused = true"
                  @focusout="isNotificationFocused = false"
                  :class="seen ? 'mt-[6px]' : 'mb-2'"
                  class="dark:bg-gray-800 p-1 rounded-full text-gray-400 dark:hover:text-white hover:text-gray-600"
                >
                  <span class="sr-only">View notifications</span>
                  <!-- Heroicon name: outline/bell -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>

                <div
                  class="dropdown-menu hidden absolute right-0 mt-2 border-2 bg-white rounded-md shadow-lg overflow-hidden z-20"
                  style="width: 20rem"
                >
                  <!-- <div class="py-2 border-b-2 border-gray-400">
                  <p class="text-gray-600 text-center mx-2 mr-2 font-bold">
                    Notifications
                  </p>
                </div> -->
                  <div v-if="!username" class="py-2 border-b">
                    <router-link
                      to="/area/settings"
                      class="flex flex-col px-4 py-3 hover:bg-gray-100 -mx-2 mv"
                    >
                      <div class="flex items-center">
                        <div class="w-[75px]">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            class="w-6 mx-auto"
                            x="0px"
                            y="0px"
                            viewBox="0 0 1000 1000"
                            enable-background="new 0 0 1000 1000"
                            xml:space="preserve"
                          >
                            <g>
                              <path
                                d="M394.4,942.1l47-160.1c4.9-16.9,14.6-32.2,28.2-44.3l120.5-119.4c-9.6-3.6-19.2-7-28.8-10.1c-6-2-44.2-19.2-20.3-91.6h-0.4c62.1-63.9,109.6-166.9,109.6-268.2c0-155.8-103.7-237.5-224.1-237.5c-120.5,0-223.5,81.7-223.5,237.5c0,101.7,47.2,205.1,109.7,268.9c24.3,63.9-19.2,87.6-28.3,90.9C158,653.8,10,737,10,819c0,22.2,0,8.7,0,30.7c0,105.4,192.6,134,382.8,136.9C390.1,972.1,390.1,956.9,394.4,942.1z"
                              />
                              <path
                                d="M831.6,657.3l-0.2,0.2l-36.8-36.9l0.2-0.2l-49.3-49.3L521.2,793.5c-3.1,2.5-5.7,5.6-6.9,9.7l-47,160.3c-2.1,7-0.1,14.6,5,19.7c3.8,3.8,8.9,5.9,14.1,5.9c1.9,0,3.7-0.3,5.6-0.8l160.4-47.1c3.8-1.1,6.8-3.5,9.1-6.3L886.3,712L831.6,657.3z M612.6,842.1l2.9,51.6l13,12.9l-86.2,25.3l-18.8-18.8l25.3-86.2l10.8,10.8L612.6,842.1z"
                              />
                              <path
                                d="M765.8,548.8l140.8,140.8l32.2-30.2L798.1,518.6L765.8,548.8z"
                              />
                              <path
                                d="M976,546.8l-67.5-67.5c-18.6-18.6-48.6-18.8-67.1-0.4l-20.7,20.9l135,135l20.7-21C994.7,595.5,994.6,565.4,976,546.8z"
                              />
                            </g>
                          </svg>
                        </div>
                        <p class="text-gray-600 text-sm mx-2 mr-2">
                          Update your
                          <span class="font-bold" href="#">Username!</span>
                        </p>
                      </div>

                      <a
                        class="text-blue-500 hover:text-blue-700 text-xs font-bold py-1 px-2 rounded-full mx-auto"
                      >
                        Go to settings
                      </a>
                    </router-link>
                  </div>
                  <div class="py-2">
                    <a
                      href="#"
                      class="flex items-center px-4 py-3 hover:bg-gray-100 -mx-2"
                    >
                      <span class="mx-2">👋</span>
                      <p class="text-gray-600 text-sm mx-2 mr-2">
                        Welcome to
                        <span class="font-bold" href="#">Keen Readers!</span>
                      </p>
                    </a>
                  </div>
                  <!-- <a
                  href="#"
                  class="block bg-gray-800 text-white text-center font-bold py-2"
                  >See all notifications</a
                > -->
                </div>
              </div>

              <!-- Profile dropdown -->
              <div class="ml-3 relative group">
                <div>
                  <button
                    type="button"
                    class="dark:bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-white"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    ref="button"
                    @click="dropdownToggle"
                    @focusin="isFocused = true"
                    @focusout="isFocused = false"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      v-if="svgpi"
                      class="h-8 w-8 rounded-full"
                      :src="
                        'https://api.dicebear.com/7.x/micah/svg?seed=' +
                        svgpi +
                        '&mouth=smirk,smile,laughing,surprised,pucker'
                      "
                      alt="Profile image"
                    />
                    <div
                      v-else
                      class="h-8 w-8 rounded-full pulse bg-gray-200"
                      alt="Profile image"
                    ></div>
                  </button>
                </div>
                <div class="dropdown-menu hidden" id="dropdown-menu">
                  <transition
                    enter-active-class="ease-out duration-100"
                    enter-class="opacity-0 scale-95"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="ease-in duration-75"
                    leave-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-95"
                  >
                    <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->
                    <div
                      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                      tabindex="-1"
                    >
                      <div class="py-3 px-4">
                        <span
                          class="block text-sm font-bold text-gray-900 dark:text-white"
                          >Hi 👋</span
                        >
                        <span
                          v-if="username"
                          class="block text-sm font-medium text-gray-500 truncate dark:text-gray-400"
                          >{{ username }}</span
                        >
                        <span
                          v-else
                          class="block text-sm font-medium text-gray-500 truncate dark:text-gray-400"
                          >{{ email }}</span
                        >
                      </div>
                      <!-- Active: "dark:bg-gray-100", Not Active: "" -->
                      <router-link
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabindex="-1"
                        id="user-menu-item-0"
                        to="/area/profile"
                        >Your Profile</router-link
                      >
                      <router-link
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabindex="-1"
                        id="user-menu-item-1"
                        to="/area/settings"
                      >
                        Settings
                      </router-link>
                      <button
                        class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabindex="-1"
                        id="user-menu-item-2"
                        @click="showModal = true"
                      >
                        Invite your friends
                      </button>
                      <button
                        class="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        tabindex="-1"
                        id="user-menu-item-2"
                        @click="Logout"
                      >
                        Sign out
                      </button>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!-- <Teleport to="body"> -->
      <!-- use the modal component, pass in the prop -->
      <Modal :show="showModal" @close="showModal = false">
        <!-- <template #header> </template> -->
      </Modal>
      <!-- </Teleport> -->
    </div>
    <div
      class="dark:bg-gray-800 border-t border-t-slate-300 z-50 fixed bottom-0 w-full"
    >
      <nav class="opacity-96 bg-slate-50">
        <div class="sm:hidden" id="mobile-menu">
          <div class="px-2 pt-2 pb-8 space-y-1">
            <!-- Current: "dark:bg-gray-900 text-white", Default: "text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white hover:text-gray-600" -->
            <div class="flex space-x-8 justify-center">
              <!-- Current: "dark:bg-gray-900 text-white", Default: "text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white hover:text-gray-600" -->
              <IconButton
                v-for="item in this.navigation"
                :key="item.name"
                :item="item"
              ></IconButton>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/LogoSvg.vue";
import Modal from "@/components/ModalComp.vue";
import IconButton from "@cmp/NavBar/IconButton";
import { sendNotification } from "@utils/notifications";
import firebase from "firebase/compat/app";
import mixpanel from "mixpanel-browser";
import user from "@services/user.js";

export default {
  name: "NavBar",
  data() {
    return {
      navigation: [
        {
          name: "Home",
          src: "home.svg",
          srcfocus: "home-focus.svg",
          route: "/area/home",
        },
        {
          name: "Favourites",
          src: "bookmark.svg",
          srcfocus: "bookmark-focus.svg",
          route: "/area/favourites",
        },
        {
          name: "Lists",
          src: "book.svg",
          srcfocus: "book-focus.svg",
          route: "/area/mybooks",
        },
        {
          name: "Users",
          src: "user.svg",
          srcfocus: "user-focus.svg",
          route: "/area/users",
        },
        {
          name: "Search for Books",
          src: "add.svg",
          srcfocus: "add.svg",
          route: "/area/add",
        },
      ],
      email: firebase.auth().currentUser.email,
      username: firebase.auth().currentUser.displayName,
      svgpi: "",
      showModal: false,
      isFocused: true,
      isNotificationFocused: true,
      seen: false,
      isNotificationFocusedMobile: true,
      seenMobile: false,
    };
  },
  components: {
    IconButton,
    Logo,
    Modal,
  },
  created() {
    this.seen = localStorage.seen ?? false;
    // this.username = this.email.split("@")[0];
    // localStorage.svgpi =
    // "https://api.dicebear.com/7.x/micah/svg?seed=" +
    // this.Shuffle(this.username.replace(/[^a-zA-Z0-9-_]/g, ""));
    // this.svgpi = localStorage.svgpi;
    this.getUser();
    this.$root.$on("updateProPic", (profilepic, username) => {
      this.svgpi = profilepic;
      this.username = username;
    });
  },
  watch: {
    $route() {
      this.isFocused = false;
      this.isNotificationFocused = false;
      this.$refs.notification.blur();
      this.$refs.button.blur();
    },
  },
  methods: {
    async getUser() {
      let { data } = await user.getUser();
      let userData = data;
      this.username = userData.username;
      this.svgpi = userData.profilepic;
      localStorage.svgpi = this.svgpi;
    },
    Shuffle(text) {
      let a = text.split(""),
        n = a.length;

      for (var i = n - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
      }
      return a.join("");
    },
    dropdownToggle() {
      if (this.isFocused == false) {
        this.$refs.button.blur();
        this.isFocused = true;
        return;
      }
      this.$refs.button.focus();
      if (this.isFocused) {
        this.isFocused = false;
      }
    },
    notificationToggle() {
      if (this.isNotificationFocused == false) {
        this.$refs.notification.blur();
        this.isNotificationFocused = true;
        return;
      }
      this.$refs.notification.focus();
      if (this.isNotificationFocused) {
        this.isNotificationFocused = false;
        if (!this.seen) {
          this.seen = true;
          localStorage.seen = true;
        }
      }
    },
    notificationToggleMobile() {
      if (this.isNotificationFocusedMobile == false) {
        this.$refs.notificationMobile.blur();
        this.isNotificationFocusedMobile = true;
        return;
      }
      this.$refs.notificationMobile.focus();
      if (this.isNotificationFocusedMobile) {
        this.isNotificationFocusedMobile = false;
        if (!this.seenMobile) {
          this.seenMobile = true;
          localStorage.seenMobile = true;
        }
      }
    },
    async Logout() {
      await firebase
        .auth()
        .signOut()
        .then(() => {
          if (process.env.NODE_ENV === "production") {
            mixpanel.track("Log out");
          }
          sendNotification(`bye bye 👋,\nsuccessfully logged out`, "success");
          // alert("Successfully logged out");
          // Remove router push to avoid redundant navigation to the location
          // The firebase observer in the router automatically redirect to the protected area
          // this.$router.push("/");
        })
        .catch((/*error*/) => {
          // alert(error.message);
          sendNotification(
            `An error occurred ⚠️,\nPlease clear the cache`,
            "error"
          );
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
.group:focus-within > .dropdown-menu {
  display: block;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .group:focus-within > .dropdown-menu {
    @apply block;
  }
}
</style>
