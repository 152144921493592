<template>
  <div
    class="w-full min-h-screen font-['DM Sans','sans-serif'] text-gray-900 scroll-smooth"
  >
    <div class="bg-gradient-to-b to-white">
      <img
        src="https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/public/img/blob.svg"
        alt="background image"
        class="absolute top-0 right-0 pointer-events-none"
      />
      <div class="px-6 mx-auto max-w-6xl">
        <div class="flex justify-between items-center py-10">
          <Logo alt="Logo Keen Readers" />
          <nav class="flex items-center space-x-10">
            <!-- <a
              v-for="item in menu"
              :key="item"
              href="#"
              class="text-gray-900 hover:text-gray-700"
            >
              {{ item }}
            </a> -->
            <router-link
              to="signin"
              aria-current="sign in"
              class="font-semibold text-indigo-600 hover:text-indigo-800"
            >
              Sign in
            </router-link>
            <router-link
              to="invitation"
              aria-current="sign up"
              class="font-semibold text-indigo-600 hover:text-indigo-800"
            >
              Sign up
            </router-link>
          </nav>
        </div>
      </div>
      <div class="px-6 mx-auto max-w-6xl">
        <div
          class="flex flex-col-reverse items-center md:flex-row lg:items-end"
        >
          <div
            class="pt-24 pr-8 pb-24 text-center md:pb-12 md:w-1/2 md:text-left"
          >
            <!-- <h1
              class="relative z-10 text-5xl font-bold md:text-slogan font-['Volkhov','serif']"
            >
              Don't
              <span
                class="relative after:content-[url(https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/public/img/scratch.svg)] after:absolute after:-top-2 after:-right-13 after:z-[-1]"
              >
                forget
              </span>
              a book
              <span
                class="relative after:content-[url(https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/public/img/decoration.svg)] after:absolute after:-bottom-2 after:-right-8 after:z-[-1]"
              >
                anymore.
              </span>
            </h1> -->
            <h1
              class="relative z-10 text-5xl font-bold md:text-slogan font-['Volkhov','serif']"
            >
              The app
              <span
                class="relative after:content-[url(https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/public/img/scratch.svg)] after:absolute after:-top-2 after:-right-12 lg:after:-right-48 after:z-[-1]"
              >
                for
              </span>
              those who love
              <span
                class="relative after:content-[url(https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/public/img/decoration.svg)] after:absolute after:-bottom-2 after:-right-1 after:z-[-1]"
              >
                reading.
              </span>
            </h1>
            <p
              class="pt-8 text-lg leading-relaxed text-gray-500 md:max-w-md md:text-xl"
            >
              <!-- One app for all your readings needs, made by book lovers for book
              lovers. -->
              One app for all your readings, made by book lovers for book
              lovers.
            </p>
            <div
              class="flex justify-center items-center pt-12 space-x-6 md:justify-start"
            >
              <router-link
                to="invitation"
                class="flex justify-center items-center py-4 px-8 font-bold tracking-wide leading-7 text-white bg-blue-600 rounded-xl hover:shadow-xl"
              >
                Get Started
              </router-link>
              <a href="#features" class="flex items-center">
                <!-- <PlayButton class="drop-shadow-lg hover:drop-shadow-xl" /> -->
                <span class="pl-4 font-bold tracking-wide">Learn More</span>
              </a>
            </div>
          </div>
          <div class="flex items-end w-1/2 border-gray-400">
            <img src="@assets/img/Hero Landing.png" alt="Landing image" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-[#F6FAFD]" id="features">
      <div class="py-12 px-6 mx-auto max-w-6xl">
        <div class="flex flex-col gap-5 md:flex-row">
          <div
            v-for="feature in features"
            :key="feature.title"
            class="flex md:basis-1/3 flex-col p-8 space-y-4 md:max-w-sm bg-white rounded-lg border border-gray-200 transition-shadow duration-500 ease-out cursor-pointer hover:shadow-xl"
          >
            <div class="flex items-center space-x-3">
              <div class="relative">
                <span
                  :class="feature.color"
                  class="absolute top-0 left-4 w-3 h-3 rounded-full opacity-50"
                />
                <img
                  :src="feature.source"
                  class="relative"
                  alt="feature image"
                />
              </div>
              <div class="relative">
                <span
                  :class="feature.color"
                  class="absolute right-0 bottom-1 w-12 h-1.5 opacity-60"
                />
                <h2 class="relative text-xl font-display">
                  {{ feature.title }}
                </h2>
              </div>
            </div>
            <p class="text-sm leading-relaxed text-gray-500">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="p-4 bg-white border-t-gray-200 border-t md:px-6 md:py-4 dark:bg-gray-800 w-full"
    >
      <div class="flex flex-col items-center md:flex-row md:justify-center">
        <a href="#" class="flex items-center mb-4">
          <Logo class="h-8" alt="Logo Keen Readers" />
        </a>
        <ul
          class="flex flex-wrap items-center mb-3 text-sm text-gray-500 dark:text-gray-400"
        >
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6">About</a>
          </li>
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
          </li>
          <li>
            <a href="#" class="mr-4 hover:underline md:mr-6"
              >Terms of service</a
            >
          </li>
          <li>
            <a href="#" class="hover:underline md:mr-6">Contact</a>
          </li>
        </ul>
      </div>
      <hr class="my-6 border-gray-200 mx-auto dark:border-gray-700" />
      <span class="block text-sm text-gray-500 text-center dark:text-gray-400"
        >© 2022 <a href="#" class="hover:underline">Keen Readers</a>. All Rights
        Reserved.
      </span>
    </footer>
  </div>
</template>

<script>
import Logo from "@/components/LogoSvg.vue";

export default {
  name: "LandingPage",
  components: {
    Logo,
  },
  data() {
    return {
      features: [
        {
          source:
            "https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/src/icon/briefcase.svg",
          title: "The indipendent book app",
          description:
            "We are not backed by big e-commerce company or big book stores chains. So we just recommend you the most beautiful books.",
          color: "bg-[#D9AC68]",
        },
        {
          source:
            "https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/src/icon/location.svg",
          title: "One place for all your readings",
          description:
            "Keep track of all your books and readings in one place. That's it.",
          color: "bg-[#3EC1F3]",
        },
        {
          source:
            "https://raw.githubusercontent.com/tailwindcollections/tailwind-wfh/651013a77e58ace74d01f05b629113a38299aece/src/icon/profile.svg",
          title: "Discover new books",
          description:
            "Are you looking for your next reading? We recommend you the best books based on your tastes.",
          color: "bg-[#F990BC]",
        },
      ],
      // menu: ["Home", "About us", "Contact"],
      // menu: ["Home"],
    };
  },
};
</script>

<style></style>
