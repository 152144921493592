import api from "@services/axiosInstance";
import axios from "axios";

function getUser() {
  return api.get("/user");
}

function getUserByUsername(username) {
  return api.get("/user/" + username);
}

function createUser(username, profilepic, desc, country) {
  return api.post("/user", { username, profilepic, desc, country });
}

function editUser(username, profilepic, desc, country) {
  return api.put("/user", { username, profilepic, desc, country });
}

function searchUser(query) {
  return api.get("/user/search/" + query);
}

function deleteUser() {
  return api.delete("/user");
}

function checkUsername(username) {
  return axios.get(
    `${process.env.VUE_APP_API_URL}user/checkunique/${username}`
  );
}

export default {
  getUser,
  getUserByUsername,
  createUser,
  editUser,
  searchUser,
  deleteUser,
  checkUsername,
};
