import api from "@services/NYTAPIInstance";
import buildFullPath from "axios/lib/core/buildFullPath";

const cacheName = "NYTAPI";
const apiKey = process.env.VUE_APP_NYTAPIKEY;

// Pass "hardcover-fiction" as list (always)
async function getList(list) {
  var fullPath = buildFullPath(
    "https://api.nytimes.com/svc/books/v3/",
    "lists.json?list=" + list + "&api-key=" + apiKey
  );
  if ("caches" in window) {
    try {
      const cache = await caches.open(cacheName);
      const responsec = await cache.match(fullPath);
      if (responsec) {
        // If there is an entry in the cache for event.request, then response will be defined
        // and we can just return it. Note that in this example, only font resources are cached.

        let object = await responsec.json();
        // console.log(" Found response in cache:", object);

        if (responsec.status == 200) {
          return { data: object };
        } else {
          return api.get("/lists.json?list=" + list + "&api-key=" + apiKey);
        }
      } else {
        return api.get("/lists.json?list=" + list + "&api-key=" + apiKey);
      }
    } catch (error) {
      return api.get("/lists.json?list=" + list + "&api-key=" + apiKey);
    }
  }
  return api.get("/lists.json?list=" + list + "&api-key=" + apiKey);
}

export default {
  getList,
};
