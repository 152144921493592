import axios from "axios";
import firebase from "firebase/compat/app";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.VUE_APP_TIMEOUT,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // `maxRedirects` defines the maximum number of redirects to follow in node.js.
  // If set to 0, no redirects will be followed.
  maxRedirects: process.env.VUE_APP_MAXREDIRECTS,
  // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
  //   maxContentLength: 2000,
  // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
  //   maxBodyLength: 2000,
});

// Add a request interceptor
instance.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    config.headers["authorization"] = await firebase
      .auth()
      .currentUser.getIdToken();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Response data", error.response.data);
      console.log("Response status", error.response.status);
      console.log("Response headers", error.response.headers);
      if (error.response.status === 401 && !originalRequest._retry) {
        // only retry request once
        originalRequest._retry = true;
        //refresh token
        instance.defaults.headers.common["authorization"] = await firebase
          .auth()
          .currentUser.getIdToken();
        return instance(originalRequest);
      } else {
        return Promise.reject(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("Request", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log("Config", error.config);
    const originalRequest = error.config;
  }
);

export default instance;
