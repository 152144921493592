import api from "@services/axiosInstance";
import axios from "axios";

function getCode() {
  return api.get(`/invitation/generate`);
}

function verify(invitationCode) {
  return axios.get(
    `${process.env.VUE_APP_API_URL}invitation/verify/${invitationCode}`
  );
}

function confirm(invitationCode) {
  return api.get(`/invitation/confirm/${invitationCode}`);
}

export default {
  getCode,
  verify,
  confirm,
};
