<template>
  <div class="h-full">
    <router-view />
    <PopupPWAInstall />
  </div>
</template>

<script>
import PopupPWAInstall from "@/components/PopupPWAInstall.vue";
export default {
  components: {
    PopupPWAInstall,
  },
};
</script>

<style src="gleap/build/index.min.css" />
