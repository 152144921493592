<template>
  <svg
    class="cursor-default select-none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="182"
    height="49"
    viewBox="0 0 182 49"
  >
    <defs>
      <clipPath id="a">
        <rect
          width="182"
          height="49"
          transform="translate(-652 857)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g transform="translate(652 -857)" clip-path="url(#a)">
      <g transform="translate(-653 847)">
        <text
          transform="translate(0 34)"
          font-size="32"
          font-family="Poppins"
          font-weight="600"
        >
          <tspan x="0" y="0">Keen</tspan>
        </text>
        <text
          transform="translate(49 57)"
          font-size="32"
          font-family="Poppins"
          font-weight="600"
        >
          <tspan x="0" y="0">Readers</tspan>
        </text>
        <rect
          width="92"
          height="7"
          rx="3.5"
          transform="translate(86 25)"
          fill="#14e0d2"
        />
        <rect
          width="44"
          height="8"
          rx="4"
          transform="translate(2 37)"
          fill="#ffac3c"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "LogoSvg",
};
</script>
