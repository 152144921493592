var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booksData != 0)?_c('div',{staticClass:"space-y-4 mt-4 flex flex-col justify-items-center pb-6"},[(_vm.range && _vm.booksData.length > 0)?_c('div',{staticClass:"relative flex py-5 items-center"},[_c('div',{staticClass:"flex-grow border-t-2 border-gray-400"}),_c('span',{staticClass:"flex-shrink mx-4 text-gray-600 text-2xl font-semibold"},[_vm._v(_vm._s(_vm.range))]),_c('div',{staticClass:"flex-grow border-t-2 border-gray-400"})]):_vm._e(),(_vm.dataType === 'fetched')?_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.x",value:(true),expression:"true",modifiers:{"x":true}},{name:"dragscroll",rawName:"v-dragscroll.y",value:(false),expression:"false",modifiers:{"y":true}}],staticClass:"grid cursor-grab py-5 flex-row mx-auto",class:_vm.grid
        ? _vm.profile
          ? '2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-8'
          : '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 gap-8 mx-auto'
        : 'scroll auto-cols-min grid-flow-col gap-16 overflow-y-auto',on:{"dragscrollstart":_vm.onDragStart,"!click":function($event){return _vm.onDragClick.apply(null, arguments)}}},_vm._l((_vm.booksData),function(item,index){return _c('BookCard',{key:index,staticClass:"snap-center",attrs:{"book":item,"type":_vm.cardType,"list":_vm.list,"readCount":_vm.readCount ? _vm.readCount[item.id] : null,"score":_vm.bookrefs ? _vm.bookrefs[index].score : null,"id":_vm.id ? _vm.id[index].id : null}})}),1):_c('div',[(_vm.isMobile)?_c('div',{staticClass:"grid cursor-grab px-10 py-4 flex-row",class:_vm.grid
          ? '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2'
          : 'scroll auto-cols-min grid-flow-col gap-16 snap-x'},_vm._l((_vm.booksData),function(item,index){return _c('BookCard',{key:index,staticClass:"snap-center",attrs:{"book":item,"type":_vm.cardType,"list":_vm.list}})}),1):_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.x",modifiers:{"x":true}}],staticClass:"grid cursor-grab px-10 py-4 flex-row",class:_vm.grid
          ? '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2'
          : 'scroll auto-cols-min grid-flow-col gap-16',on:{"dragscrollstart":_vm.onDragStart,"!click":function($event){return _vm.onDragClick.apply(null, arguments)}}},_vm._l((_vm.booksData),function(item,index){return _c('BookCard',{key:index,staticClass:"snap-center",attrs:{"book":item,"type":_vm.cardType,"list":_vm.list}})}),1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }