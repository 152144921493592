import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./index.css";
import Gleap from "gleap";
import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');
import Rollbar from "rollbar";

import "./registerServiceWorker";

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV === "production" ? "production" : "dev",
    // Track your events to a specific version of code for better visibility into version health
    code_version: "1.0.0",
    // Add custom data to your events by adding custom key/value pairs like the one below
    // custom_data: "foo",
  },
});

// If you have already set up a global error handler,
// just add `vm.$rollbar.error(err)` to the top of it.
// If not, this simple example will preserve the app’s existing
// behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
if (process.env.NODE_ENV === "production") {
  mixpanel.init(process.env.VUE_APP_MIXPANEL, {
    debug: process.env.NODE_ENV === "production" ? false : true,
  });
}

// Gleap initialization
// Please make sure to call this method only once!
Gleap.initialize(process.env.VUE_APP_GLEAP);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = firebase.messaging();

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
messaging
  .getToken({ vapidKey: process.env.VUE_APP_VAPID })
  .then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      console.log(currentToken);
      // Handle incoming messages. Called when:
      // - a message is received while the app has focus
      // - the user clicks on an app notification created by a service worker
      //   `messaging.onBackgroundMessage` handler.
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
        // ...
      });
    } else {
      // Show permission request UI
      console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.config.productionTip = process.env.NODE_ENV === "production" ? true : false;

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
