<template>
  <!--
    This page requires:
    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div
    class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <router-link to="/">
          <Logo class="mx-auto h-12 w-auto" alt="Logo Keen Readers" />
        </router-link>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          {{ " " }}
          <router-link
            to="/invitation"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Sign up
          </router-link>
        </p>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="SubmitAuth">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email address"
              v-model="email"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Password"
              v-model="password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              v-model="checked"
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              Remember me
            </label>
          </div>

          <div class="text-sm">
            <router-link
              to="forgot"
              class="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Forgot your password?
            </router-link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/LogoSvg.vue";

import { sendNotification } from "@utils/notifications";
import firebase from "firebase/compat/app";
import mixpanel from "mixpanel-browser";

export default {
  name: "SignInView",
  components: {
    Logo,
  },
  data() {
    return {
      email: "",
      password: "",
      checked: false,
    };
  },
  methods: {
    SubmitAuth() {
      firebase
        .auth()
        .setPersistence(
          this.checked
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        )
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password);
        })
        .then(() => {
          if (process.env.NODE_ENV === "production") {
            mixpanel.track("Sign in");
          }
          sendNotification(`Welcome`, "success");
          // alert("Successfully logged in");
          // Remove router push to avoid redundant navigation to the location
          // The firebase observer in the router automatically redirect to the protected area
          // this.$router.push("/area");
        })
        .catch((/*error*/) => {
          sendNotification(`Problema durante il login, ricontrollare`, "error");
          // alert(error.message);
          // console.log(error.code);
          // console.log(error.message);
        });
    },
  },
};
</script>
