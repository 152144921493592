<template>
  <router-link
    :title="item.name"
    ref="myButton"
    :to="item.route"
    :class="[$route.path.includes(item.route) ? 'active' : 'notActive']"
    class="text-gray-300 dark:hover:bg-gray-700 hover:bg-gray-100 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    aria-current="page"
    @click.native="scrollToTop"
  >
    <img
      v-if="isActive"
      class="h-5 w-auto"
      :src="require(`@icons/${item.srcfocus}`)"
      :alt="item.name"
    />
    <img
      v-else
      class="h-5 w-auto"
      :src="require(`@icons/${item.src}`)"
      :alt="item.name"
    />
  </router-link>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    item: Object,
  },
  data() {
    return {
      isActive: false,
    };
  },
  mounted() {
    this.isActive = this.$refs.myButton.$el.classList
      .toString()
      .includes("active");
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.isActive = this.$refs.myButton.$el.classList
          .toString()
          .includes("active");
      }, 50);
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
