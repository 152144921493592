<template>
  <div
    class="hover:scale-105 transition ease-in-out delay-150"
    v-if="
      book.volumeInfo &&
      book.volumeInfo.imageLinks &&
      (book.volumeInfo.industryIdentifiers
        ? book.volumeInfo.industryIdentifiers.find((x) => x.type === 'ISBN_13')
        : false)
    "
  >
    <div
      v-if="loading"
      class="border-2 shadow rounded-xl max-w-sm mx-auto h-[270px] w-44"
    >
      <div class="animate-pulse flex flex-col space-x-4 p-4 h-full w-full">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 bg-slate-200 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-slate-200 rounded col-span-2"></div>
              <div class="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-slate-200 rounded"></div>
          </div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-slate-200 rounded col-span-2"></div>
              <div class="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-slate-200 rounded"></div>
          </div>
          <div class="h-2 bg-slate-200 rounded"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="h-2 bg-slate-200 rounded col-span-2"></div>
              <div class="h-2 bg-slate-200 rounded col-span-1"></div>
            </div>
            <div class="h-2 bg-slate-200 rounded"></div>
          </div>
          <div class="h-2 bg-slate-200 rounded"></div>
        </div>
      </div>
    </div>
    <div
      v-show="!loading"
      @click="$router.push({ path: '/area/book/' + book.id })"
    >
      <div
        v-if="type === 'normal-full'"
        class="flex flex-col justify-center h-[270px] w-[720px] cursor-pointer"
      >
        <div
          class="w-full relative flex flex-row space-x-5 space-y-0 rounded-xl py-4 px-2 shadow-lg max-w-3xl border border-white bg-white"
        >
          <div class="w-1/5 bg-white grid place-items-center">
            <img
              @load="loading = false"
              alt="Book Image"
              :src="book.volumeInfo.imageLinks.thumbnail"
              class="rounded-xl w-full h-full"
            />
          </div>
          <div class="w-4/5 bg-white flex flex-col space-y-2 p-3">
            <div class="flex justify-between item-center">
              <p class="text-gray-500 font-medium hidden md:block line-clamp-1">
                <span
                  v-for="(element, index) in book.volumeInfo.categories"
                  :key="element"
                >
                  <span v-if="index != 0">, </span>{{ element }}
                </span>
              </p>
              <div
                class="flex items-center"
                v-if="book.volumeInfo.averageRating"
              >
                <vue-reaction-emoji
                  class="scale-75"
                  style="position: absolute"
                  :reaction="
                    score && score != 0
                      ? reactions[Math.round(score - 1)]
                      : reactions[Math.round(book.volumeInfo.averageRating - 1)]
                  "
                  :is-active="true"
                  :is-disabled="true"
                />
                <p class="text-gray-600 font-bold text-sm ml-14">
                  {{ book.volumeInfo.averageRating }}
                </p>
              </div>
              <div
                class="bg-yellow-300 px-3 py-1 rounded-full text-sm font-medium text-gray-800 hidden md:block"
              >
                Bestseller
              </div>
            </div>
            <h3
              class="font-black text-gray-800 md:text-2xl text-xl line-clamp-2"
            >
              {{ book.volumeInfo.title }}
              <span
                class="text-base font-normal text-gray-600 line-clamp-2"
                v-if="book.volumeInfo.authors"
              >
                By {{ book.volumeInfo.authors.join(", ") }}
              </span>
            </h3>
            <p class="md:text-base text-gray-500 text-md line-clamp-4">
              {{ book.volumeInfo.description }}
            </p>
          </div>
        </div>
      </div>
      <div
        @mouseover="hovered = true"
        v-if="type === 'normal' || type === 'reading' || type === 'read'"
        class="w-[180px] h-[270px] cursor-pointer group-1 rounded-xl text-sm"
      >
        <div
          id="card"
          class="rounded-xl relative preserve-3d w-full h-full duration-1000"
          :class="isMobile ? '' : 'group-1-hover:card-flip'"
        >
          <div
            class="rounded-xl absolute backface-hidden border-2 w-full h-full"
          >
            <div
              v-if="readCount > 1"
              class="bg-[#fede84] text-blue-800 dark:bg-[#fede84] dark:text-blue-800 absolute -right-2 -top-2 rounded-full min-w-[32px] p-1.5 text-center font-semibold"
            >
              {{ readCount }}
            </div>
            <img
              @load="loading = false"
              alt="Book Image"
              v-if="book.volumeInfo.imageLinks"
              :src="book.volumeInfo.imageLinks.thumbnail"
              class="rounded-xl w-full h-full"
            />
          </div>
          <div
            v-if="!isMobile"
            class="rounded-xl absolute card-flip backface-hidden w-full h-full overflow-hidden"
            :class="getRandomColor()"
          >
            <div
              class="text-center flex flex-col items-center justify-center h-full text-gray-800 px-2 space-y-2"
            >
              <p
                class="text-gray-500 font-medium hidden md:block"
                v-if="categories"
              >
                <span>
                  {{ categories.slice(0, 2).join(", ") }}
                </span>
              </p>
              <h3 class="font-black text-gray-800">
                <p class="text-base line-clamp-4">
                  {{ book.volumeInfo.title }}
                </p>
                <p
                  class="font-normal text-gray-600 line-clamp-2"
                  v-if="book.volumeInfo.authors"
                >
                  By {{ book.volumeInfo.authors.join(", ") }}
                </p>
              </h3>
              <!-- <AddButton
                :class="book.volumeInfo.averageRating ? '' : 'pb-8'"
                class="mt-2 z-10"
                v-if="button"
                :book="book"
                :btnOnly="true"
                :small="true"
              /> -->
              <div
                v-if="book.volumeInfo.averageRating || score"
                class="flex flex-col items-center pt-2 mt-2 border-t-2"
              >
                <div
                  v-if="score && score != 0"
                  class="font-bold font-mono text-gray-600"
                >
                  Your score
                </div>
                <div class="flex flex-row items-center">
                  <vue-reaction-emoji
                    :reaction="
                      score && score != 0
                        ? reactions[Math.round(score - 1)]
                        : reactions[
                            Math.round(book.volumeInfo.averageRating - 1)
                          ]
                    "
                    :is-active="true"
                    :is-disabled="true"
                  />
                  <div class="text-gray-600 font-bold text-sm ml-1">
                    <p v-if="score && score != 0">{{ score }}</p>
                    <p v-else>
                      {{ book.volumeInfo.averageRating }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <BookmarkInput
                v-if="hovered && type === 'reading'"
                :id="id"
                :maxPages="this.book.volumeInfo.pageCount"
                :card="true"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="type === 'special'" class="flex flex-col justify-center">
        <div
          class="bg-gray-100 w-full flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl py-4 px-2 shadow-lg max-w-xs md:max-w-3xl mx-auto border transition hover:cursor-pointer ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
        >
          <div class="w-1/5 grid place-items-center">
            <img
              @load="loading = false"
              alt="Book Image"
              :src="book.volumeInfo.imageLinks.thumbnail"
              class="rounded-xl w-max"
            />
            <div v-if="book.volumeInfo.averageRating" class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-yellow-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                />
              </svg>
              <p class="text-gray-600 font-bold text-sm ml-1">
                {{ book.volumeInfo.averageRating }}
                <span class="text-gray-500 font-normal">(76 reviews)</span>
              </p>
            </div>
          </div>
          <div class="flex flex-col space-y-2 p-3 w-4/5">
            <div class="flex justify-between item-center">
              <div class="flex gap-4">
                <div
                  v-for="stat in stats"
                  :key="stat.id"
                  class="flex flex-row gap-4 rounded-full border-2 p-4 bg-white"
                  :class="stat.color[2]"
                >
                  <div class="my-auto px-3">
                    <div class="text-sm" :class="stat.color[0]">
                      {{ stat.name }}
                    </div>
                    <div class="text-2xl font-bold" :class="stat.color[0]">
                      {{ stat.value }}
                    </div>
                  </div>
                  <div
                    :class="stat.color[1]"
                    class="text-green-100 my-auto rounded-full p-4"
                  >
                    <svg
                      width="24px"
                      height="24px"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        :d="stat.path"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-gray-500 font-medium hidden md:block line-clamp-1">
              <span
                v-for="(element, index) in book.volumeInfo.categories"
                :key="element"
              >
                <span v-if="index != 0">, </span>{{ element }}
              </span>
            </p>
            <h3
              class="font-black text-gray-800 md:text-2xl text-xl line-clamp-2"
            >
              {{ book.volumeInfo.title }}
              <span class="text-base font-normal text-gray-600 line-clamp-2">
                By {{ book.volumeInfo.authors.join(", ") }}
              </span>
            </h3>
            <p class="md:text-base text-gray-500 text-md line-clamp-4">
              {{ book.volumeInfo.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AddButton from "@/components/Book/AddButton.vue";
// import BookmarkInput from "@/components/Book/BookmarkInput.vue";
import { VueReactionEmoji } from "vue-feedback-reaction";

export default {
  name: "BookCard",
  props: {
    book: Object,
    type: String,
    readCount: Number,
    score: Number,
    id: Number,
  },
  components: {
    // AddButton,
    VueReactionEmoji,
    // BookmarkInput,
  },
  data() {
    return {
      categories: [],
      colors: [
        "bg-blue-50",
        "bg-red-50",
        "bg-orange-50",
        "bg-amber-50",
        "bg-green-50",
        "bg-teal-50",
      ],
      stats: [
        {
          id: 1,
          name: "Pages",
          value: 0,
          path: "M4 6h16M4 12h8m-8 6h16",
          color: ["text-green-700", "bg-green-600", "bg-green-200"],
        },
        {
          id: 2,
          name: "Reads",
          value: 0,
          path: "M5 13l4 4L19 7",
          color: ["text-orange-700", "bg-orange-600", "bg-orange-200"],
        },
        {
          id: 3,
          name: "Favourites",
          value: 0,
          path: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z",
          color: ["text-red-700", "bg-red-600", "bg-red-200"],
        },
      ],
      reactions: ["hate", "disappointed", "natural", "good", "excellent"],
      button: false,
      loading: true,
      hovered: false,
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    this.originalPage = this.currentPage;
    this.stats[0].value = this.book.volumeInfo.pageCount;
    if (this.book.volumeInfo.categories)
      this.categories = this.getCategories(this.book.volumeInfo.categories);
  },
  methods: {
    getCategories(array) {
      let cats = [];
      array.forEach((element) => {
        cats = [...new Set([...cats, ...element.split(" / ")])];
      });
      if (cats[0] === "Fiction") {
        cats = cats.slice(1, cats.length);
      }
      return cats;
    },
    getRandomColor() {
      var randomNumber = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomNumber];
    },
  },
};
</script>

<style>
.group-1:hover .group-1-hover\:scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.card-flip {
  transform: rotateY(180deg);
}

.group-1:hover .group-1-hover\:card-flip {
  transform: rotateY(180deg);
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* Define background color for Firefox compatibility */
  /* Firefox doesn't support backface-visibility without transforming applied on the same element */
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
#card {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1),
    0px 1px 7px -1px rgba(0, 0, 0, 0.1);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}
</style>
<style>
.group-1:hover .group-1-hover\:scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.card-flip {
  transform: rotateY(180deg);
}

.group-1:hover .group-1-hover\:card-flip {
  transform: rotateY(180deg);
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
#card {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1),
    0px 1px 7px -1px rgba(0, 0, 0, 0.1);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}
</style>
