import axios from "axios";
import firebase from "firebase/compat/app";

// Adapter
var utils = require("axios/lib/utils");
var settle = require("axios/lib/core/settle");
var cookies = require("axios/lib/helpers/cookies");
var buildURL = require("axios/lib/helpers/buildURL");
var buildFullPath = require("axios/lib/core/buildFullPath");
var parseHeaders = require("axios/lib/helpers/parseHeaders");
var isURLSameOrigin = require("axios/lib/helpers/isURLSameOrigin");
var createError = require("axios/lib/core/createError");
var transitionalDefaults = require("axios/lib/defaults/transitional");
var Cancel = require("axios/lib/cancel/Cancel");

const cacheName = "recommendation";

const CustomAdapter = function (config) {
  return new Promise(function dispatchXhrRequest(resolve, reject) {
    var requestData = config.data;
    var requestHeaders = config.headers;
    var responseType = config.responseType;
    var onCanceled;
    function done() {
      if (config.cancelToken) {
        config.cancelToken.unsubscribe(onCanceled);
      }

      if (config.signal) {
        config.signal.removeEventListener("abort", onCanceled);
      }
    }

    if (utils.isFormData(requestData)) {
      delete requestHeaders["Content-Type"]; // Let the browser set it
    }

    var request = new XMLHttpRequest();

    // HTTP basic authentication
    if (config.auth) {
      var username = config.auth.username || "";
      var password = config.auth.password
        ? unescape(encodeURIComponent(config.auth.password))
        : "";
      requestHeaders.Authorization = "Basic " + btoa(username + ":" + password);
    }

    var fullPath = buildFullPath(config.baseURL, config.url);
    request.open(
      config.method.toUpperCase(),
      buildURL(fullPath, config.params, config.paramsSerializer),
      true
    );

    // Set the request timeout in MS
    request.timeout = config.timeout;

    function onloadend() {
      if (!request) {
        return;
      }
      // Prepare the response
      var responseHeaders =
        "getAllResponseHeaders" in request
          ? parseHeaders(request.getAllResponseHeaders())
          : null;
      var responseData =
        !responseType || responseType === "text" || responseType === "json"
          ? request.responseText
          : request.response;

      const temp = request.responseURL;
      const status = request.status;
      const query = request.responseURL.replace(
        process.env.VUE_APP_REC_URL,
        ""
      );
      // console.log(query);
      const queryArray = query.split("/");

      if (
        "caches" in window &&
        queryArray[0] == "query" &&
        queryArray[1] == "genre"
      ) {
        // The Cache API is supported
        caches.open(cacheName).then((cache) => {
          if (status == 200) {
            cache.put(temp, new Response(responseData, { status: status }));
          }
        });
      }

      var response = {
        data: responseData,
        status: request.status,
        statusText: request.statusText,
        headers: responseHeaders,
        config: config,
        request: request,
      };

      settle(
        function _resolve(value) {
          resolve(value);
          done();
        },
        function _reject(err) {
          reject(err);
          done();
        },
        response
      );

      // Clean up request
      request = null;
    }

    if ("onloadend" in request) {
      // Use onloadend if available
      request.onloadend = onloadend;
    } else {
      // Listen for ready state to emulate onloadend
      request.onreadystatechange = function handleLoad() {
        if (!request || request.readyState !== 4) {
          return;
        }

        // The request errored out and we didn't get a response, this will be
        // handled by onerror instead
        // With one exception: request that using file: protocol, most browsers
        // will return status as 0 even though it's a successful request
        if (
          request.status === 0 &&
          !(request.responseURL && request.responseURL.indexOf("file:") === 0)
        ) {
          return;
        }
        // readystate handler is calling before onerror or ontimeout handlers,
        // so we should call onloadend on the next 'tick'
        setTimeout(onloadend);
      };
    }

    // Handle browser request cancellation (as opposed to a manual cancellation)
    request.onabort = function handleAbort() {
      if (!request) {
        return;
      }

      reject(createError("Request aborted", config, "ECONNABORTED", request));

      // Clean up request
      request = null;
    };

    // Handle low level network errors
    request.onerror = function handleError() {
      // Real errors are hidden from us by the browser
      // onerror should only fire if it's a network error
      reject(createError("Network Error", config, null, request));

      // Clean up request
      request = null;
    };

    // Handle timeout
    request.ontimeout = function handleTimeout() {
      var timeoutErrorMessage = config.timeout
        ? "timeout of " + config.timeout + "ms exceeded"
        : "timeout exceeded";
      var transitional = config.transitional || transitionalDefaults;
      if (config.timeoutErrorMessage) {
        timeoutErrorMessage = config.timeoutErrorMessage;
      }
      reject(
        createError(
          timeoutErrorMessage,
          config,
          transitional.clarifyTimeoutError ? "ETIMEDOUT" : "ECONNABORTED",
          request
        )
      );

      // Clean up request
      request = null;
    };

    // Add xsrf header
    // This is only done if running in a standard browser environment.
    // Specifically not if we're in a web worker, or react-native.
    if (utils.isStandardBrowserEnv()) {
      // Add xsrf header
      var xsrfValue =
        (config.withCredentials || isURLSameOrigin(fullPath)) &&
        config.xsrfCookieName
          ? cookies.read(config.xsrfCookieName)
          : undefined;

      if (xsrfValue) {
        requestHeaders[config.xsrfHeaderName] = xsrfValue;
      }
    }

    // Add headers to the request
    if ("setRequestHeader" in request) {
      utils.forEach(requestHeaders, function setRequestHeader(val, key) {
        if (
          typeof requestData === "undefined" &&
          key.toLowerCase() === "content-type"
        ) {
          // Remove Content-Type if data is undefined
          delete requestHeaders[key];
        } else {
          // Otherwise add header to the request
          request.setRequestHeader(key, val);
        }
      });
    }

    // Add withCredentials to request if needed
    if (!utils.isUndefined(config.withCredentials)) {
      request.withCredentials = !!config.withCredentials;
    }

    // Add responseType to request if needed
    if (responseType && responseType !== "json") {
      request.responseType = config.responseType;
    }

    // Handle progress if needed
    if (typeof config.onDownloadProgress === "function") {
      request.addEventListener("progress", config.onDownloadProgress);
    }

    // Not all browsers support upload events
    if (typeof config.onUploadProgress === "function" && request.upload) {
      request.upload.addEventListener("progress", config.onUploadProgress);
    }

    if (config.cancelToken || config.signal) {
      // Handle cancellation
      // eslint-disable-next-line func-names
      onCanceled = function (cancel) {
        if (!request) {
          return;
        }
        reject(
          !cancel || (cancel && cancel.type) ? new Cancel("canceled") : cancel
        );
        request.abort();
        request = null;
      };

      config.cancelToken && config.cancelToken.subscribe(onCanceled);
      if (config.signal) {
        config.signal.aborted
          ? onCanceled()
          : config.signal.addEventListener("abort", onCanceled);
      }
    }

    if (!requestData) {
      requestData = null;
    }

    request.send(requestData);

    // if ("caches" in window) {
    //   caches
    //     .open(cacheName)
    //     .then(function (cache) {
    //       cache
    //         .match(fullPath)
    //         .then((responsec) => {
    //           if (responsec) {
    //             // If there is an entry in the cache for event.request, then response will be defined
    //             // and we can just return it. Note that in this example, only font resources are cached.
    //             console.log(" Found response in cache");

    //             var response = {
    //               data: responsec.json(),
    //               status: responsec.status,
    //               statusText: responsec.statusText,
    //               headers: responsec.headers,
    //               config: config,
    //               request: request,
    //             };

    //             settle(
    //               function _resolve(value) {
    //                 resolve(value);
    //                 done();
    //               },
    //               function _reject(err) {
    //                 reject(err);
    //                 done();
    //               },
    //               response
    //             );

    //             // Clean up request
    //             request = null;
    //           }
    //         })
    //         .catch(() => {
    //           request.send(requestData);
    //         });
    //     })
    //     .catch(() => {
    //       request.send(requestData);
    //     });
    // } else {
    //   // Send the request
    //   request.send(requestData);
    // }
  });
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_REC_URL,
  timeout: process.env.VUE_APP_REC_TIMEOUT,
  adapter: CustomAdapter,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // `maxRedirects` defines the maximum number of redirects to follow in node.js.
  // If set to 0, no redirects will be followed.
  maxRedirects: process.env.VUE_APP_MAXREDIRECTS,
  // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
  //   maxContentLength: 2000,
  // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
  //   maxBodyLength: 2000,
});

// Add a request interceptor
instance.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    config.headers["authorization"] = await firebase
      .auth()
      .currentUser.getIdToken();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("Response data", error.response.data);
      console.log("Response status", error.response.status);
      console.log("Response headers", error.response.headers);
      if (error.response.status === 401 && !originalRequest._retry) {
        // only retry request once
        originalRequest._retry = true;
        //refresh token
        instance.defaults.headers.common["authorization"] = await firebase
          .auth()
          .currentUser.getIdToken();
        return instance(originalRequest);
      } else {
        return Promise.reject(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("Request", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    console.log("Config", error.config);
    const originalRequest = error.config;
  }
);

export default instance;
