<template>
  <div>
    <div class="mt-16 lg:mb-12 mb-8 mx-auto px-10 max-w-3xl">
      <div
        class="bg-white rounded-xl flex items-center w-full p-2 shadow-sm border border-gray-200 pr-5"
      >
        <input
          type="search"
          @keydown.enter="getBooks()"
          placeholder="Search for books"
          v-model="q"
          class="w-full pl-4 ml-2 focus:ring-0 text-base bg-transparent rounded-l-lg border-0"
        />
        <button @click="getBooks()" class="rounded-xl">
          <svg
            class="w-5 text-gray-600 h-5 cursor-pointer"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </div>
    </div>
    <BookChallenge v-show="loading" class="md:mx-auto" />
    <LoadingComp v-if="loading" class="mt-24" />
    <div v-else>
      <!-- Recommended and TopRated -->
      <div
        v-if="recommendations.length >= 4"
        class="flex flex-col xl:flex-row mt-10"
      >
        <div class="xl:w-1/2 xl:mr-5">
          <div
            class="ml-10 flex flex-row items-center"
            v-if="recommendations.length >= 4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 mr-4 text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
              />
            </svg>
            <h1
              class="text-2xl xl:text-3xl text-gray-700 font-extrabold font-sans"
            >
              Recommended for you
            </h1>
          </div>
          <SingleList
            v-if="recommendations.length >= 4"
            :books="recommendations"
            :dataType="'titles'"
            :key="ckey"
          />
        </div>
        <div class="xl:w-1/2 mt-12 xl:mt-0 xl:ml-5">
          <div
            class="ml-10 flex flex-row items-center"
            v-if="topscored.length >= 4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 mr-4 text-yellow-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
              />
            </svg>
            <h1
              class="text-2xl xl:text-3xl text-gray-700 font-extrabold font-sans"
            >
              Top scored
            </h1>
          </div>
          <SingleList
            v-if="topscored.length >= 4"
            :books="topscored"
            :dataType="'titles'"
            :key="ckey"
          />
        </div>
      </div>
      <div v-else>
        <BookChallenge v-show="!loading" class="md:mx-auto my-2" />
        <div
          class="mt-8 ml-10 flex flex-row items-center"
          v-if="topscored.length >= 4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 mr-4 text-yellow-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>
          <h1 class="text-3xl text-gray-700 font-extrabold font-sans">
            Top scored
          </h1>
        </div>
        <SingleList
          v-if="topscored.length >= 4"
          :books="topscored"
          :dataType="'home'"
          :key="ckey"
        />
      </div>
      <!-- Bestseller NYT -->
      <div class="mt-10">
        <div
          class="ml-10 flex flex-row items-center"
          v-if="topPicks.length >= 4"
        >
          <img
            src="@assets/img/TimesLogo.png"
            alt="NYT Logo"
            class="h-8 w-8 mr-4"
          />
          <h1
            class="text-2xl xl:text-3xl text-gray-700 font-extrabold font-sans"
          >
            Selected Bestsellers
          </h1>
        </div>
        <SingleList
          v-if="topPicks.length >= 4 && isMobile"
          :books="topPicks"
          :dataType="'titles'"
          :key="ckey"
        />
        <SingleList
          v-else
          :books="topPicks"
          :cardType="'normal-full'"
          :dataType="'titles'"
          :key="ckey"
        />
      </div>

      <!-- <div class="flex flex-col ml-10 mt-16" v-if="topscored.length >= 4">
        <h1 class="text-3xl text-gray-700 font-extrabold font-sans">
          Today's pick
        </h1>
      </div> -->
      <!-- <div class="mx-10">
        <BookCard :book="todayBook" :type="'normal-full'" :list="''" />
        <div class="grid xl:grid-cols-2 justify-center"></div>
      </div> -->

      <!-- Genres -->
      <!-- <div class="mt-3">
        <div :key="ck" class="space-y-16">
          <div
            v-for="(item, index) in genreBookNames"
            :key="index"
            class="space-y-5"
          >
            <div class="flex flex-col ml-10">
              <h1
                class="text-2xl xl:text-3xl text-gray-700 font-extrabold font-sans"
              >
                {{ index }}
              </h1>
            </div>
            <SingleList :books="item" :dataType="'titles'" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SingleList from "@/components/List/SingleList.vue";
// import BookCard from "@/components/List/BookCard.vue";
import BookChallenge from "@/components/Book/BookChallenge.vue";
import LoadingComp from "@/components/LoadingComp.vue";
import test from "@services/ping.js";
import genre from "@services/genre.js";
import recs from "@services/recs.js";
import topScored from "@services/topScored.js";
import NYTAPI from "@services/NYTAPI.js";
import google from "@services/googleInstance.js";
import buildFullPath from "axios/lib/core/buildFullPath";

// import google from "@services/googleInstance.js";

export default {
  name: "HomeView",
  components: {
    SingleList,
    // BookCard,
    BookChallenge,
    LoadingComp,
  },
  data() {
    return {
      q: "",
      genreBookNames: {},
      booksData: [],
      // todayTitle: ["Il giro del mondo in 80 giorni", "l'isola misteriosa"],
      // todayBook: [],
      recommendations: [],
      topscored: [],
      temp: [],
      genres: [
        "Classics",
        "Fiction",
        "Adventure",
        "Novels",
        "Action & Adventure",
      ],
      ck: 0,
      ckey: 0,
      loading: true,
      NYT: "hardcover-fiction",
      topPicks: [],
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    if (!sessionStorage.genre) {
      this.deleteCache();
      sessionStorage.genre = true;
    }
    console.log(await test.ping());
    // for (let e in this.todayTitle) {
    //   let { data } = await google.get(
    //     "/volumes?q=" + this.todayTitle[e] + "&maxResults=1"
    //   );
    //   this.todayBook.push(data.items[0]);
    // }
    await this.getNYT();
    // await this.getRecommendations();
    await this.getTopScored();
    // await this.getGenreData();
    this.loading = false;
  },
  methods: {
    getBooks() {
      if (this.q != "")
        this.$router.replace({ path: "/area/add/", query: { q: this.q } });
    },
    async getNYT() {
      let { data } = await NYTAPI.getList(this.NYT);
      for (var a in data.results) {
        this.topPicks.push(data.results[a].book_details[0].title);
      }
    },
    async getRecommendations() {
      let { data } = await recs.getRecommendations();
      this.recommendations = data;
      await recs
        .getTopScored()
        .then(
          (response) =>
            (this.topscored = this.shuffle(response.data.splice(0, 20)))
        );
      this.ckey++;
    },
    async getTopScored() {
      let { data } = await topScored.getTopScored();
      this.temp = data;
      await this.fetchFromGoogle();
      this.ckey++;
    },
    async getGenreData() {
      await Promise.all(this.genres.map((e) => genre.getGenreData(e)))
        .then((responses) =>
          responses.forEach(
            (r, i) =>
              (this.genreBookNames[this.genres[i]] = this.shuffle(
                r.data.splice(0, 20)
              ))
          )
        )
        .catch((/*error*/) => {
          // console.log(error);
        });
      this.ck += 1;
    },
    getRandom(arr, n) {
      var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    },
    async deleteCache() {
      if ("caches" in window) {
        await caches.delete("recommendation");
      }
    },
    async fetchFromGoogle() {
      await Promise.all(
        this.temp.map(async (e) => {
          var fullPath = buildFullPath(
            "https://www.googleapis.com/books/v1/",
            "/volumes/" + e
          );
          if ("caches" in window) {
            try {
              const responsec = await this.cache.match(fullPath);
              if (responsec) {
                // If there is an entry in the cache for event.request, then response will be defined
                // and we can just return it. Note that in this example, only font resources are cached.

                let object = await responsec.json();
                // console.log(" Found response in cache:", object);

                if (responsec.status == 200) {
                  return { data: object };
                } else {
                  return google.get("/volumes/" + e);
                }
              } else {
                return google.get("/volumes/" + e);
              }
            } catch (error) {
              console.log(error);
              return google.get("/volumes/" + e);
            }
          }
          return google.get("/volumes/" + e);
        })
      ).then((responses) =>
        responses.forEach((r) => this.topscored.push(r.data))
      );
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
};
</script>

<style scoped>
::-webkit-search-cancel-button {
  cursor: pointer;
}
</style>
