<template>
  <div
    class="space-y-4 mt-4 flex flex-col justify-items-center pb-6"
    v-if="booksData != 0"
  >
    <div
      class="relative flex py-5 items-center"
      v-if="range && booksData.length > 0"
    >
      <div class="flex-grow border-t-2 border-gray-400"></div>
      <span class="flex-shrink mx-4 text-gray-600 text-2xl font-semibold">{{
        range
      }}</span>
      <div class="flex-grow border-t-2 border-gray-400"></div>
    </div>
    <div
      v-if="dataType === 'fetched'"
      v-dragscroll.x="true"
      v-dragscroll.y="false"
      @dragscrollstart="onDragStart"
      @click.capture="onDragClick"
      :class="
        grid
          ? profile
            ? '2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-8'
            : '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 gap-8 mx-auto'
          : 'scroll auto-cols-min grid-flow-col gap-16 overflow-y-auto'
      "
      class="grid cursor-grab py-5 flex-row mx-auto"
    >
      <BookCard
        v-for="(item, index) in booksData"
        :key="index"
        class="snap-center"
        :book="item"
        :type="cardType"
        :list="list"
        :readCount="readCount ? readCount[item.id] : null"
        :score="bookrefs ? bookrefs[index].score : null"
        :id="id ? id[index].id : null"
      />
    </div>
    <div v-else>
      <div
        v-if="isMobile"
        :class="
          grid
            ? '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2'
            : 'scroll auto-cols-min grid-flow-col gap-16 snap-x'
        "
        class="grid cursor-grab px-10 py-4 flex-row"
      >
        <BookCard
          v-for="(item, index) in booksData"
          :key="index"
          class="snap-center"
          :book="item"
          :type="cardType"
          :list="list"
        />
      </div>
      <div
        v-else
        v-dragscroll.x
        @dragscrollstart="onDragStart"
        @click.capture="onDragClick"
        :class="
          grid
            ? '2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2'
            : 'scroll auto-cols-min grid-flow-col gap-16'
        "
        class="grid cursor-grab px-10 py-4 flex-row"
      >
        <BookCard
          v-for="(item, index) in booksData"
          :key="index"
          class="snap-center"
          :book="item"
          :type="cardType"
          :list="list"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BookCard from "@/components/List/BookCard.vue";
import { dragscroll } from "vue-dragscroll";

import google from "@services/googleInstance.js";
import buildFullPath from "axios/lib/core/buildFullPath";

const cacheName = "googleapi";

export default {
  name: "SingleList",
  props: {
    books: Array,
    list: {
      type: String,
      default: "",
    },
    dataType: {
      type: String,
      default: "fetched",
    },
    cardType: {
      type: String,
      default: "normal",
    },
    grid: { type: Boolean, default: false },
    profile: { type: Boolean, default: false },

    search: {
      type: String,
      default: "",
    },
    range: String,
    readCount: {
      type: Object,
    },
    bookrefs: Array,
    id: Array,
    filter: String,
  },
  components: { BookCard },
  directives: {
    dragscroll: dragscroll,
  },
  data() {
    return {
      booksData: [],
      originalData: [],
      cache: {},
      dragged: false,
      dragTimeout: null,
    };
  },
  watch: {
    books() {
      if (this.dataType != "titles") {
        this.originalData = this.books;
      }
      this.filterBySearch();
    },
    search() {
      this.filterBySearch();
    },
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    if ("caches" in window) {
      try {
        this.cache = await caches.open(cacheName);
      } catch (error) {
        // console.log(error);
      }
    }

    if (this.dataType != "titles") {
      this.originalData = this.books;
    } else {
      await this.fetchFromGoogle();
      this.booksData = this.booksData.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      if (this.filter) {
        this.booksData = this.booksData.filter((x) => x.id != this.filter);
      }
    }
    this.filterBySearch();
  },
  methods: {
    filterBySearch() {
      if (this.dataType != "titles") {
        this.booksData = this.originalData;
        if (this.search != "")
          this.booksData = this.booksData.filter((o) =>
            o.volumeInfo.title.toLowerCase().includes(this.search.toLowerCase())
          );
      }
    },
    async fetchFromGoogle() {
      await Promise.all(
        this.books.map(async (e) => {
          var fullPath = buildFullPath(
            "https://www.googleapis.com/books/v1/",
            "/volumes?q=" + e + "&maxResults=1"
          );
          if ("caches" in window) {
            try {
              const responsec = await this.cache.match(fullPath);
              if (responsec) {
                // If there is an entry in the cache for event.request, then response will be defined
                // and we can just return it. Note that in this example, only font resources are cached.

                let object = await responsec.json();
                // console.log(" Found response in cache:", object);

                if (responsec.status == 200) {
                  return { data: object };
                } else {
                  return google.get("/volumes?q=" + e + "&maxResults=1");
                }
              } else {
                return google.get("/volumes?q=" + e + "&maxResults=1");
              }
            } catch (error) {
              console.log(error);
              return google.get("/volumes?q=" + e + "&maxResults=1");
            }
          }
          return google.get("/volumes?q=" + e + "&maxResults=1");
        })
      ).then((responses) =>
        responses.forEach((r) => this.booksData.push(r.data.items[0]))
      );
    },
    // async getBooksDataByTitle(title) {
    //   var fullPath = buildFullPath(
    //     "https://www.googleapis.com/books/v1/",
    //     "/volumes?q=" + title + "&maxResults=1"
    //   );
    //   if ("caches" in window) {
    //     try {
    //       const responsec = await this.cache.match(fullPath);
    //       if (responsec) {
    //         // If there is an entry in the cache for event.request, then response will be defined
    //         // and we can just return it. Note that in this example, only font resources are cached.

    //         let object = await responsec.json();
    //         // console.log(" Found response in cache:", object);

    //         if (responsec.status == 200) {
    //           this.booksData.push(object.items[0]);
    //         } else {
    //           google
    //             .get("/volumes?q=" + title + "&maxResults=1")
    //             .then((response) =>
    //               this.booksData.push(response.data.items[0])
    //             );
    //         }
    //       } else {
    //         google
    //           .get("/volumes?q=" + title + "&maxResults=1")
    //           .then((response) => this.booksData.push(response.data.items[0]));
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       google
    //         .get("/volumes?q=" + title + "&maxResults=1")
    //         .then((response) => this.booksData.push(response.data.items[0]));
    //     }
    //   } else {
    //     google
    //       .get("/volumes?q=" + title + "&maxResults=1")
    //       .then((response) => this.booksData.push(response.data.items[0]));
    //   }
    // },
    onDragStart() {
      clearTimeout(this.dragTimeout);

      this.dragged = false;
      this.dragTimeout = setTimeout(() => {
        this.dragged = true;
      }, 50); // Minimal delay to be regarded as drag instead of click
    },
    onDragClick(e) {
      if (this.dragged) {
        e.stopImmediatePropagation();
      }
      this.dragged = false;
    },
  },
};
</script>

<style>
.scroll::-webkit-scrollbar {
  overflow-y: auto;
  height: 8px;
}
.scroll::-webkit-scrollbar-track {
  background-color: rgba(232, 232, 232, 0.854);
  border-radius: 10px;
  margin-left: 40px;
  margin-right: 40px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(34, 147, 233, 0.854);
  border-radius: 10px;
}
.scroll {
  overflow-y: auto;
}
.scroller::-webkit-scrollbar-button:end:increment {
  height: 80%;
  display: block;
  background: transparent;
}
</style>
