<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container overflow-x-hidden overflow-y-auto sm:w-full md:w-4/5 lg:w-3/5 xl:w-2/5"
        >
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              @click="$emit('close')"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div class="px-6 py-6 lg:px-8">
              <h3 class="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
                Invite your friends to Keen Readers
              </h3>
              <div class="space-y-6" action="#">
                <div>
                  <label
                    for="link"
                    class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >This is your invitation link. Every link is unique and is
                    valid for 24 hours. The only way to sign up for Keen Readers
                    is through invitation by a member. <br />
                    We are committed to keeping this community exclusive and
                    safe so please share this link carefully. <br />
                    Thank you.</label
                  >
                  <input
                    name="link"
                    id="link"
                    placeholder="Generating your invitation. Please wait."
                    class="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                    readonly
                    v-model="link"
                  />
                  <div
                    class="text-sm font-semibold text-gray-600 text-right mt-1"
                  >
                    <span v-if="remained > 1" class="text-green-600"
                      >You have {{ remained }} invitations left</span
                    >
                    <span v-else-if="remained === 1" class="text-green-600"
                      >You have {{ remained }} invitation left</span
                    >
                    <span v-else class="text-red-500"
                      >You have no more invitations left</span
                    >
                  </div>
                  <div
                    v-if="link"
                    class="flex flex-row justify-center items-center mt-2 mb-1"
                  >
                    <qrcode-vue
                      :value="link"
                      size="120"
                      level="H"
                      class="mx-auto"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="link"
                class="flex flex-row justify-center items-center mt-2 mb-1"
              >
                <div><span class="font-bold">Scan</span> 📸 or</div>
              </div>
              <button
                class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm mt-3 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                @click="invite"
              >
                <span v-if="loading">
                  <svg
                    class="text-white mx-auto w-7 h-7"
                    version="1.1"
                    id="L9"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enable-background="new 0 0 0 0"
                    xml:space="preserve"
                  >
                    <path
                      fill="currentColor"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </span>
                <span v-else>Copy and share the link</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import invitation from "@services/invitation.js";
import QrcodeVue from "qrcode.vue";
import { sendNotification } from "@utils/notifications";

export default {
  name: "ModalComp",
  components: {
    QrcodeVue,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      link: "",
      remained: 0,
      loading: true,
    };
  },
  watch: {
    async show() {
      this.link = "";
      if (this.show == true) {
        invitation
          .getCode()
          .then((response) => {
            if (response.status == 200) {
              this.link = response.data.link ?? "";
              this.remained = response.data.remained ?? 0;
            }
            this.loading = false;
          })
          .catch((/*error*/) => {
            this.link = "";
            this.remained = 0;
            // console.log(error);
          });
      }
    },
  },
  methods: {
    invite() {
      navigator.clipboard.writeText(this.link);
      sendNotification(`Link copied! Share and enjoy!`, "success");
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
