import api from "@services/axiosInstance";

function getReads() {
  return api.get("/read");
}

function addRead(bookrefid, myscore) {
  myscore = myscore ?? 0;
  return api.post("/read", {
    bookrefid,
    myscore,
  });
}

function reRead(id) {
  return api.put("/read/count/" + id, {});
}

function editReview(id, myscore) {
  return api.put("/read/" + id, {
    myscore,
  });
}

export default {
  getReads,
  addRead,
  reRead,
  editReview,
};
