import api from "@services/recAxiosInstance";

function getSimilarBooks(genre) {
  return api.get("/query/recommend/books/bygenre/" + genre);
}

function getBooksByAuthorGenre(author, genre) {
  return api.get("/query/author/" + author + "/genre/" + genre);
}

function getRecommendations() {
  return api.get("/query/recommend/books");
}

function getTopScored() {
  return api.get("/query/recommend/books/topscore");
}

export default {
  getSimilarBooks,
  getRecommendations,
  getTopScored,
  getBooksByAuthorGenre,
};
