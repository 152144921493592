<template>
  <div class="flex flex-col min-h-screen">
    <Banner
      class="hidden md:block"
      :show="showBanner"
      @showModalEvent="showModal = true"
      @closeBanner="showBanner = false"
    />
    <!-- <Teleport to="body" name="teleport"> -->
    <!-- use the modal component, pass in the prop -->
    <Modal :show="showModal" @close="showModal = false">
      <!-- <template #header> </template> -->
    </Modal>
    <!-- </Teleport> -->
    <NavBar />
    <router-view class="mb-auto" />
    <MadeWithLove class="mb-6 sm:mb-0" />
  </div>
</template>

<script>
import NavBar from "@cmp/NavBar/NavBar";
import Banner from "@cmp/BannerComp";
import Modal from "@/components/ModalComp.vue";
import MadeWithLove from "@/components/MadeWithLove.vue";
import firebase from "firebase/compat/app";

export default {
  name: "WindowView",
  data() {
    return {
      showBanner: true,
      showModal: false,
    };
  },
  components: {
    NavBar,
    Banner,
    Modal,
    MadeWithLove,
  },
  created() {
    this.GetToken();
  },
  mounted() {
    setTimeout(() => {
      this.showBanner = false;
    }, 10000);
  },
  methods: {
    GetToken() {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (token) {
          console.log(token);
        });
    },
  },
};
</script>

<style></style>
